const Enums = () => {
    const Site = { ADMIN: 0, CGY: 1, POC: 2, VAN: 3, TBY: 4, MTL: 5 }
    const SiteName = {'CGY': 'Calgary', 'POC': 'Pocatello', 'VAN': 'Vancouver', 'TBY': 'Thunder Bay', 'MTL': 'Montreal'}
    const ActionBtn = { DELETE: 0, OK: 1, ARCHIVE_UNARCHIVE: 2, SEND_PICK: 3, SEND_RAF: 4, SEND_ERAF: 5 };
    const ResponseBtn = { OK: 'Ok', CANCEL: 'Cancel', DELETE: 'Delete', SELECT: 'Select', SAVING: 'Saving...', YES: 'Yes', NO: 'No' };
    const FormBtn = { SAVE_CHANGES: 'Save Changes', CREATE: 'Create', CLOSE: 'Close' }
    const FormMode = { ADD: 0, EDIT: 1 };
    const Form = { 
        STEEP: 'Steep', 
        GERM: 'Germination', 
        KILN: 'Kiln', 
        ROAST: 'Roast', 
        MALTHOUSE: 'Malthouse', 
        SITE: 'Site',
        BINS: {
            SITE_BINS: 'SiteBinLocations', 
            BIN_TYPE: 'BinType', 
            BIN_ROLE: 'BinRole', 
            BIN_SHAPE: 'BinShape', 
        },
        TYPE: {
            AERATION: 'aeration',
            CO2_EXTRACT: 'co2-extract',
            DRAIN: 'drain',
            HUMIDIFY: 'humidify',
            TURN: 'turn',
            OVERFLOW: 'overflow',
            FILL: 'Fill',
        }
    };
    const RecipeProcess = { MANAGE: 'Manage', PROD_ORDER: 'ProdOrder' };
    const RecipeStepType = { AIR_ON: 'Air On', AIR_OFF: 'Air Off', RECIRC: 'Recirc', TURN: 'Turn', FILL_DRAIN: 'Fill Drain', REDUCT: 'Reduct' };
    const PickList = { MALTHOUSE: 'malthouse', STEEP: 'steep', GERM: 'germination', KILN: 'kiln', ROAST: 'Roast' };
    const RecipePickerView = { BATCH: 'Batch', BATCH_EDIT: 'Batch_Edit', DASHBOARD: 'Dashboard' };
    const FUMESStatus = {
        SOFTDELETED: { Code: -100, Desc: 'Deleted', BtnCopy: 'N/A', BtnDisabled: true },
        D365_CONNECTIONISSUE: { Code: -99, Desc: 'Error Sending Start To D365', BtnCopy: 'Error', BtnDisabled: true },
        ERROR: { Code: -1, Desc: 'Error Starting Order', BtnCopy: 'Error', BtnDisabled: true },
        CREATED: { Code: 0, Desc: "Created", BtnCopy: (e) => `Start ${e}`, BtnDisabled: true },
        SCHEDULED: { Code: 1, Desc: "Scheduled", BtnCopy: (e) => `Start ${e}`, BtnDisabled: true },
        RELEASED_FROM_365FO_TO_FUMESDB: { Code: 2, Desc: "Released", BtnCopy: (e) => `Start ${e}`, BtnDisabled: false },
        //Used as intermediate before sending or pushing a start command up to D365.
        FUMES_AVEVA_STARTED: { Code: 3, Desc: "Sending to D365...", BtnCopy: (e) => "Send Final RAF and Report As Finish", BtnDisabled: true },
        FUMES_MANUAL_STARTED: { Code: 4, Desc: "Sending to D365...", BtnCopy: (e) => "Send Final RAF and Report As Finish", BtnDisabled: true },

        START_QUEUED: { Code: 5, Desc: "D365 Processing...", BtnCopy: (e) => "Send Final RAF and Report As Finish", BtnDisabled: true },
        START_POSTED: { Code: 6, Desc: "Started", BtnCopy: (e) => "Send Final RAF and Report As Finish", BtnDisabled: true },

        RESTARTED: { Code: 7, Desc: "Started", BtnCopy: (e) => "Mark FUMES Completed", BtnDisabled: true }, //Deprecated
        FUMES_COMPLETED: { Code: 8, Desc: "FUMES Completed", BtnCopy: (e) => "Set To Started", BtnDisabled: true },  //Deprecated

        ENDRAF_POSTED: { Code: 9, Desc: "Report As Finished", BtnCopy: (e) => "Mark FUMES Completed", BtnDisabled: true },
        ENDED: { Code: 10, Desc: "Ended", BtnCopy: (e) => "Ended", BtnDisabled: true }
    };
    const Route = {
        Type: {
            CLEANING: 'Cleaning', BATCH: 'Batch', PRE_BLEND: 'Pre-Blend', BLENDING: 'Blending'
        }, 
        ListOptions: [
            {
                id: 0,
                name: "All"
            },
            {
                id: 1,
                name: "Cleaning"
            },
            {
                id: 2,
                name: "Batch"
            },
            {
                id: 3,
                name: "Pre-Blend"
            }
        ]
    }
    

    const PICKLineStatus = {
        LOADED: 0,
        BLENDPARENT_LOADED: 1,
        DUPLICATE: 2,
        BLENDPARENT_DUPLICATE: 3,
        JOURNAL_LINE_ADDED_TO_DB: 4,
        BLENDPARENT_JOURNAL_LINE_ADDED_TO_DB: 5,
    }

    const RAFLineStatus = {
        LOADED: 0,
        DUPLICATE: 1,
        JOURNAL_LINE_ADDED_TO_DB: 2
    }

    const JournalStatus = {
        UNDEFINED: -99,
        ERROR: -1,
        CREATED: 1,
        QUEUED: 2,
        WAITING_D365: 3,
        PROCESSED_D365: 4
    }

    const Operation = {
        Hold: 5,
        Steep: 10,
        Clean: 10,  //Clean jobs will only use Operation 10
        Steep2_PreGerm: 20,
        Germ: 30,
        Kiln_KilnUp: 40,
        KilnDwn: 50,
        KilnHopper: 55,
        Transfer: 60,
        Roast: 70
    }

    const Actual = {
        END: 0,
        START: 1
    }

    const ConfigProfile = {
        Site: 'site',
        SiteBinLocations: 'site-bin-locations',
        ProductView: 'product-view',
        Malthouse: 'malthouse',
        BinType: 'bin-type',
        BinShape: 'bin-shape',
        BinRole: 'bin-role',
        Aeration: 'aeration',
        CO2Extract: 'co2-extract',
        Drain: 'drain',
        Humidify: 'humidify',
        Turn: 'turn',
        Overflow: 'overflow',
        Fill: 'fill'
    }

    return {
        ActionBtn,
        FormMode,
        ResponseBtn,
        FormBtn,
        Form,
        RecipeStepType,
        RecipeProcess,
        PickList,
        RecipePickerView,
        FUMESStatus,
        PICKLineStatus,
        RAFLineStatus,
        JournalStatus,
        Site,
        SiteName,
        Operation,
        Actual,
        ConfigProfile,
        Route
    };
};

export default Enums;
